<template />

<script>
export default {
  name: 'Quickbooks',
}
</script>

<style scoped>

</style>
